<template>
  <b-card header-tag="header" header-text-variant="primary" body-class="px-2">
    <!-- HEADER -->
    <template #header>
      <b-card-title>
        Products
        <!-- New -->
        <!-- Icon if global products not present /ELSE/ dropdown -->
        <b-icon-plus-circle @click="addNew" scale="1.5" class="float-right" />
      </b-card-title>

      <!-- Search -->
      <b-form-input
        type="search"
        id="search"
        v-model="search"
        @update="onFilterChange"
        placeholder="Search"
        class="mt-4"
        trim
      />
    </template>

    <!-- Show MRP Product config -->
    <merchant-product-config-form />

    <b-row>
      <b-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="product in products"
        :key="product.id"
      >
        <MerchantProduct :product="product" class="mb-3" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <MarketPrice class="small mt-3" />
      </b-col>
    </b-row>

    <!-- Pagination -->
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="count"
        :per-page="perPageCount"
        @change="getPaginatedProducts"
        align="center"
      ></b-pagination>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getProducts, getProductsCount } from "../../../apis/merchant";

import MarketPrice from "../../../components/common/MarketPrice.vue";

import MerchantProduct from "../../../components/products/merchant/MerchantProduct";
import MerchantProductConfigForm from "../../../components/products/merchant/MerchantProductConfigForm.vue";

export default {
  components: {
    MerchantProduct,
    MarketPrice,
    MerchantProductConfigForm,
  },
  created() {
    this.initProducts();
  },
  data() {
    return {
      search: "",

      // for pagination
      currentPage: 1,
      perPageCount: 20,
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      products: "merchant/products/getList",
    }),
    apiParams() {
      return {
        name: this.search,
        page: this.currentPage - 1,
        size: this.perPageCount,
        sort: "id,desc", // for sorting by id desc
      };
    },
  },
  methods: {
    ...mapActions({
      setProducts: "merchant/products/setList",
    }),
    addNew() {
      // redirect to global list
      this.$router.push({ name: "GlobalProductsList" });
    },
    initProducts() {
      const params = this.apiParams;
      getProductsCount(this.token, params).then((data) => {
        this.count = data.count;
        this.getPaginatedProducts(this.currentPage);
      });
    },
    getPaginatedProducts(page) {
      // first set the page
      this.currentPage = page;

      const params = this.apiParams;
      getProducts(this.token, params).then((data) => {
        this.setProducts(data);
      });
    },
    onFilterChange(newValue) {
      this.search = newValue;
      this.initProducts();
    },
  },
};
</script>
