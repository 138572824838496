<template>
  <div>
    <!-- Display MRP -->
    <template v-if="displayMrp">
      <!-- MRP -->
      <p :class="pClass">
        <s class="mr-1">
          {{ mrp | rupeeCurrency }}
        </s>
        / {{ unit }}
      </p>

      <!-- Price -->
      <p :class="pClass">
        <span class="mr-1">
          {{ price.toString() | rupeeCurrency }}
        </span>

        <!-- Discord -->
        ({{ discount }}% off)
      </p>
    </template>

    <!-- Dont Display MRP -->
    <template v-else>
      <p :class="pClass">
        <span class="mr-1">
          <template v-if="price">
            {{ price.toString() | rupeeCurrency }}
          </template>
          <template v-else> {{ "**" | rupeeCurrency }} </template>
        </span>
        / {{ unit }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mrp: this.product.mrp,
      unit: this.product.unit,
      price: this.product.price,

      pClass: "store-price-unit small m-0 p-0 mb-1",
    };
  },
  computed: {
    displayMrp() {
      // if no price or no mrp, return false
      if (!this.price || !this.mrp) return false;
      // if mrp less or equals price, return false
      if (this.mrp <= this.price) return false;
      // if discount is 0, return false
      if (!this.discount) return false;
      // if global flag to hide is true, return false
      if (this.store.mrpHidden) return false;
      return true;
    },
    discount() {
      // if no price or no mrp, return 0
      if (!this.price || !this.mrp) return 0;
      const difference = this.mrp - this.price;
      const discount = (difference / this.mrp) * 100;
      const truncatedDiscount = Math.trunc(discount);
      return truncatedDiscount;
    },
  },
};
</script>

<style></style>
