<template>
  <product-card :imageSrc="imageSrc">
    <!-- Title -->
    <div class="store-product-title text-wrap">
      {{ title }}
    </div>

    <!-- Product Price & Unit -->
    <product-price-and-unit
      v-if="Boolean(store)"
      :product="product"
      :store="store"
    />

    <!-- Available Button To Change from product directly -->
    <b-form-group>
      <b-form-checkbox
        v-model="form.available"
        name="available"
        @change="updateAvailability"
      >
        <small>List</small>
      </b-form-checkbox>
    </b-form-group>

    <!-- Options -->
    <div class="d-flex justify-content-center align-items-center">
      <b-button
        @click="onEditClick"
        size="sm"
        variant="primary"
        class="mr-4 py-0"
      >
        Edit
      </b-button>
      <b-button @click="onDeleteClick" size="sm" class="py-0">
        Del
      </b-button>
    </div>
  </product-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { updateProduct, deleteProduct } from "../../../apis/merchant";

import ProductCard from "../../common/ProductCard.vue";
import ProductPriceAndUnit from "../ProductPriceAndUnit.vue";

export default {
  components: { ProductCard, ProductPriceAndUnit },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getMerchantProductImages({
      productId: this.product.id,
      token: this.token,
    }).then((images) => (this.productImages = [...images]));
  },
  data() {
    return {
      // product images
      productImages: [],

      title: this.product.name,
      price: this.product.price,
      unit: this.product.unit,

      form: {
        available: this.product.available,
      },
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      store: "merchant/store/getStore",
    }),
    imageSrc() {
      const productImages = this.productImages;
      if (productImages.length > 0) {
        return productImages[0].src;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      addProductToState: "merchant/products/addItem",
      removeProduct: "merchant/products/removeItem",
      getMerchantProductImages: "productImages/getMerchantProductImages",
    }),
    onEditClick() {
      // redirect to form with product ID
      this.$router.push({
        name: "ProductForm",
        params: { productId: this.product.id },
      });
    },
    onDeleteClick() {
      deleteProduct(this.product.id, this.token).then(() => {
        // remove from state, will automatically render
        this.removeProduct({ id: this.product.id });
      });
    },
    updateAvailability() {
      const available = this.form.available;
      const categoryId = this.product.category
        ? this.product.category.id
        : null;
      const { name, price, unit, description } = this.product;
      const form = { name, categoryId, price, unit, description, available };
      updateProduct(this.product.id, form, this.token).then((data) => {
        this.addProductToState(data);
      });
    },
  },
};
</script>
