<template>
  <b-form class="text-center mb-2">
    <!-- Show MRP -->
    <b-form-checkbox
      v-model="form.mrpHidden"
      name="mrpHidden"
      @change="changeMrpHidden"
      switch
    >
      Hide MRP
    </b-form-checkbox>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    // creating form from store
    const { mrpHidden, autoWhatsappOrder } = this.store;
    this.form = { mrpHidden, autoWhatsappOrder };
  },
  data() {
    return {
      form: {
        mrpHidden: false,
        autoWhatsappOrder: false,
      },
      errors: null,
    };
  },
  computed: {
    ...mapGetters({
      store: "merchant/store/getStore",
    }),
  },
  methods: {
    ...mapActions({
      updateStoreConfig: "merchant/store/updateStoreConfig",
    }),
    changeMrpHidden() {
      this.updateStoreConfig(this.form);
    },
  },
};
</script>

<style></style>
